export const setterDate = (dateValue: Date): string => {
  const date = new Date(dateValue);
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  return `${date.getDate()}.${month}.${year}<span class="ml-2 grey--text text-caption">${hour}:${minutes}</span>`;
};

export const setterAmount = (amountValue: number): string =>
  (Math.round(amountValue + Number.EPSILON) / 100).toFixed(2);

export const calculatePercentOfDate = (dateInit: Date, dateFinal: Date) => {
  const now = new Date();
  const init = new Date(dateInit);
  const final = new Date(dateFinal);
  const completTimeInSeconds = final.getTime() - init.getTime();
  const cursedTimeInSeconds = final.getTime() - now.getTime();
  return (100 - (cursedTimeInSeconds / completTimeInSeconds) * 100).toFixed(2);
};
