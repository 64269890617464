

















































import { Component, Vue } from 'vue-property-decorator';
import CustomerModel from '@/models/Customer.model';
import CustomersRepository from '@/repository/modules/customers.repository';
import { setterDate } from '@/plugins/utils';

@Component
export default class ListCustomers extends Vue {
  private customers: CustomerModel[] = [];
  page = 1;
  limit = 10;
  total_results = 0;
  loading = false;
  search_email = '';

  async mounted(): Promise<void> {
    await this.getCustomers(this.page);
  }

  get total_pages() {
    return Math.round(this.total_results / this.limit) + 1;
  }

  async getCustomers(page: number, query: object = {}): Promise<void> {
    this.loading = true;
    const { customers, total } = await CustomersRepository.getListCustomers({ query, page, limit: this.limit });
    this.customers = customers;
    this.total_results = total ?? 0;
    this.loading = false;
  }

  changePage(page: number) {
    this.getCustomers(page, this.queryBuilder());
  }

  clearAndfind() {
    this.search_email = '';
    this.findListCustomers();
  }

  findListCustomers() {
    this.page = 1;
    this.getCustomers(1, this.queryBuilder());
  }

  queryBuilder() {
    const obj: any = {};
    if (this.search_email != '') {
      obj.email = this.search_email;
    }
    return obj;
  }

  setDate = (dater: Date) => setterDate(dater);
}
